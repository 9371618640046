import React from "react";
import { blogs } from "./blog-collection";
import BlogItem from "./BlogItem";

const BlogCollection: React.FC = () => {
  return (
    <div
        style={{
          marginTop: "10vh",
          paddingBottom: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
    >
        <div 
            style={{
                maxWidth: "100%",
                display: "flex",
                flexWrap: "wrap",
                gap: "40px",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 30px"
            }}
        >
            {blogs.map((blog) => (
                <BlogItem key={blog.id} blogData={blog} />
            ))}
        </div>
    </div>
  )
}

export default BlogCollection;
