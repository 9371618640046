import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Blog } from "./blog-collection"
import theme from "../../elements/Theme";
import ResizableImage from "../../elements/ResizableImage";
import WhiteButton from "../../elements/WhiteButton";

const BlogItem: React.FC<{ blogData: Blog }> = ({
  blogData,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    const currentLanguage = i18n.language;
    const route = currentLanguage === "en" ? `/blog/${blogData?.id}` : `/${currentLanguage}/blog/${blogData?.id}`
    history.push(route);
  };

  return (
    <div
      style={{
        width: "400px",
        height: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        backgroundColor: theme.palette.secondary.main,
        padding: "14px 10px",
        borderRadius: "5px",
        boxSizing: "border-box",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h5"
        mb="14px"
        px="14px"
        textAlign="center"
        fontFamily="Open Sans Bold"
        color={theme.palette.text.secondary}
      >
        {t(`BlogPage.blogs.${blogData.titleKey}`)}
      </Typography>
      <ResizableImage
        src={blogData.image}
        alt={t(`BlogPage.blogs.${blogData.titleKey}`)}
      />
      <div
        style={{
          width: "100%",
          padding: "14px",
          boxSizing: "border-box",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Typography
          variant="h5"
          color={theme.palette.text.secondary}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        >
          {t(`BlogPage.blogs.${blogData.contentKey}`)}
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center"}}>
        <WhiteButton onClick={handleClick}>
          {t("BlogPage.see more")}
        </WhiteButton>
      </div>
    </div>
  );
};

export default BlogItem;
