export type Blog = {
  id: number;
  image: string;
  titleKey: string;
  date: string;
  contentKey: string;
};

export const blogs: Blog[] = [
  {
    id: 1,
    image: "/homePage/card1.webp",
    titleKey: "blog_title_1",
    date: "12/03/2023",
    contentKey: "blog_content_1",
  },
  {
    id: 2,
    image: "/homePage/card2.webp",
    titleKey: "blog_title_2",
    date: "15/05/2023",
    contentKey: "blog_content_2",
  },
  {
    id: 3,
    image: "/homePage/card3.webp",
    titleKey: "blog_title_3",
    date: "20/08/2023",
    contentKey: "blog_content_3",
  },
  {
    id: 4,
    image: "/homePage/card4.webp",
    titleKey: "blog_title_4",
    date: "10/02/2023",
    contentKey: "blog_content_4",
  },
  {
    id: 5,
    image: "/homePage/card5.webp",
    titleKey: "blog_title_5",
    date: "08/06/2023",
    contentKey: "blog_content_5",
  },
  {
    id: 6,
    image: "/homePage/card6.webp",
    titleKey: "blog_title_6",
    date: "30/11/2023",
    contentKey: "blog_content_6",
  },
  {
    id: 7,
    image: "/homePage/card1.webp",
    titleKey: "blog_title_7",
    date: "14/07/2023",
    contentKey: "blog_content_7",
  },
  {
    id: 8,
    image: "/homePage/card2.webp",
    titleKey: "blog_title_8",
    date: "21/09/2023",
    contentKey: "blog_content_8",
  },
  {
    id: 9,
    image: "/homePage/card3.webp",
    titleKey: "blog_title_9",
    date: "05/01/2023",
    contentKey: "blog_content_9",
  },
  {
    id: 10,
    image: "/homePage/card4.webp",
    titleKey: "blog_title_10",
    date: "17/10/2023",
    contentKey: "blog_content_10",
  },
];
